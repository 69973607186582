<template>
  <div>
    <v-container fluid>
      <v-layout
        row
        wrap>
        <!-- <v-btn
          title="Загрузить пользователей"
          color="#66bb6a"
          @click="launchFilePicker">
          Загрузить пользователей
        </v-btn>
        <v-btn
          title="Загрузить карты"
          color="#66bb6a"
          @click="launchFilePickerCards">
          Создать Карты
        </v-btn>
        <v-btn
          title="Распределить деньги"
          color="#66bb6a"
          @click="addMoneyDialog = true">
          Распределить деньги
        </v-btn> -->
        <!-- <v-btn
          title="Оформить заказы"
          color="#66bb6a"
          @click="createOrdersDialog = true">
          Оформить заказы
        </v-btn> -->
      </v-layout>
    </v-container>

    <v-container
      fill-height
      fluid
      grid-list-xl
    >
      <v-layout
        justify-center
        wrap
      >
        <v-flex
          md12
        >
          <material-card
            color="green"
            title="Клиенты"
            text=""
          >
            <v-data-table
              :headers="headers"
              :items="items"
              hide-actions
            >
              <template
                slot="headerCell"
                slot-scope="{ header }"
              >
                <span
                  class="subheading font-weight-light text-success text--darken-3"
                  v-text="header.text"
                />
              </template>
              <template
                slot="items"
                slot-scope="{ item }"
              >
                <td style="max-width=40px">
                  <v-btn color="#1867c0!important" small flat icon :to="{ name: 'Клиент', params: { id: item.id } }">
                    <v-icon>mdi-alert-circle-outline</v-icon>
                  </v-btn>
                  <v-btn title="Редактировать пользователя" color="primary" small flat icon @click="pickToEditUser(item)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn title="Удалить пользователя" color="error" small flat icon @click="pickToDeleteUser(item)" >
                    <v-icon>
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </td>
                <td>{{ item.id }}</td>
                <td>{{ item.nickname ? item.nickname : 'Никнейм не указан' }}</td>
                <td>{{ item.blockchainToken }}</td>
                <td>{{ item.registered }}</td>
              </template>
            </v-data-table>
          </material-card>
        </v-flex>
      </v-layout>
    </v-container>
    <input
      ref="file"
      type="file"
      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      style="display:none"
      value="Выберите файл"
      @change="handleFileUpload()" >

    <input
      ref="filecards"
      type="file"
      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      style="display:none"
      value="Выберите файл"
      @change="handleCardsFileUpload()" >

    <v-container fluid>
      <v-layout
        row
        wrap>
        <!-- <v-btn
          title="Добавить пользователя"
          color="#66bb6a"
          @click="addUserDialog = true">
          Добавить пользователя
        </v-btn> -->
        <!-- <v-btn
          title="Удалить пользователей (отменить нельзя)"
          color="danger"
          @click="deleteAllDialog = true">
          Удалить всех пользователей
        </v-btn> -->
      </v-layout>
    </v-container>

    <v-dialog
      v-model="addMoneyDialog"
      max-width="600">
      <v-card>
        <v-toolbar
          flat
          color="#66bb6a">
          <v-toolbar-title
            left
            color="blue"
            class="text--white">Добавить денег</v-toolbar-title>
          <v-spacer/>
          <v-btn
            icon
            @click="addMoneyDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form
            ref="form"
            v-model="valid">
            <v-layout wrap>
              <v-flex xs4 class="font-weight-bold">Сумма к распределению</v-flex>
              <v-flex xs8>
                <v-text-field
                  v-model="moneyAmount"
                  label="Сумма к распределению" />
              </v-flex>
            </v-layout>

            <v-layout wrap>
              <v-spacer/>
              <v-btn color="green darken-1" @click="transferMoney" class="white--text" :disabled="!valid">Добавить</v-btn>
              <v-btn color="red darken-1" @click.native="addMoneyDialog = false" class="white--text">Отмена</v-btn>
            </v-layout>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="createOrdersDialog"
      max-width="600">
      <v-card>
        <v-toolbar
          flat
          color="#66bb6a">
          <v-toolbar-title
            left
            color="blue"
            class="text--white">Оформить заказы</v-toolbar-title>
          <v-spacer/>
          <v-btn
            icon
            @click="createOrdersDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form
            ref="form"
            v-model="valid">
            <v-layout wrap>
              <v-flex xs4 class="font-weight-bold">Количество товара</v-flex>
              <v-flex xs8>
                <v-text-field
                  v-model="itemsAmount"
                  label="Количество товара" />
              </v-flex>
            </v-layout>

            <v-layout wrap>
              <v-spacer/>
              <v-btn color="green darken-1" @click="createOrders" class="white--text" :disabled="!valid">Добавить</v-btn>
              <v-btn color="red darken-1" @click.native="createOrdersDialog = false" class="white--text">Отмена</v-btn>
            </v-layout>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="deleteAllDialog"
      max-width="600">
      <v-card>
        <v-toolbar
          flat
          color="#66bb6a">
          <v-toolbar-title
            left
            color="blue"
            class="text--white">Удалить всех пользователей</v-toolbar-title>
          <v-spacer/>
          <v-btn
            icon
            @click="deleteAllDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form
            ref="form"
            v-model="valid">
            <v-layout wrap>
              <v-flex xs12 class="font-weight-bold">Вы действительно хотите удалить всех пользователей? Операция не обратима.</v-flex>
            </v-layout>

            <v-layout wrap>
              <v-spacer/>
              <v-btn color="green darken-1" @click="deleteAll" class="white--text" :disabled="!valid">Удалить</v-btn>
              <v-btn color="red darken-1" @click.native="deleteAllDialog = false" class="white--text">Отмена</v-btn>
            </v-layout>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="deleteUserDialog"
      max-width="600">
      <v-card>
        <v-toolbar
          flat
          color="#66bb6a">
          <v-toolbar-title
            left
            color="blue"
            class="text--white">Удалить пользователя</v-toolbar-title>
          <v-spacer/>
          <v-btn
            icon
            @click="deleteUserDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form
            ref="form"
            v-model="valid">
            <v-layout wrap>
              <v-flex xs12 class="font-weight-bold">Вы действительно хотите удалить этого пользователя? Операция не обратима.</v-flex>
            </v-layout>

            <v-layout wrap>
              <v-spacer/>
              <v-btn color="green darken-1" @click="deleteUser" class="white--text" :disabled="!valid">Удалить</v-btn>
              <v-btn color="red darken-1" @click.native="deleteUserDialog = false" class="white--text">Отмена</v-btn>
            </v-layout>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="editUserDialog"
      max-width="600">
      <v-card>
        <v-toolbar
          flat
          color="#66bb6a">
          <v-toolbar-title
            left
            color="blue"
            class="text--white">Редактировать пользователя</v-toolbar-title>
          <v-spacer/>
          <v-btn
            icon
            @click="editUserDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form
            ref="form"
            v-model="valid">
            <v-layout wrap>
              <v-flex xs4 class="font-weight-bold">Полное имя пользователя</v-flex>
              <v-flex xs8>
                <v-text-field
                  v-model="selectedUser.nickname" 
                  label="Имя пользователя" 
                  required
                  />
              </v-flex>
            </v-layout>

            <v-layout wrap>
              <v-spacer/>
              <v-btn color="green darken-1" @click="editUser" class="white--text" :disabled="!valid">Изменить</v-btn>
              <v-btn color="red darken-1" @click.native="editUserDialog = false" class="white--text">Отмена</v-btn>
            </v-layout>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="addUserDialog"
      max-width="600">
      <v-card>
        <v-toolbar
          flat
          color="#66bb6a">
          <v-toolbar-title
            left
            color="blue"
            class="text--white">Добавить пользователя</v-toolbar-title>
          <v-spacer/>
          <v-btn
            icon
            @click="addUserDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form
            ref="form"
            v-model="valid">
            <v-layout wrap>
              <v-flex xs4 class="font-weight-bold">Полное имя пользователя</v-flex>
              <v-flex xs8>
                <v-text-field
                  v-model="newUser.nickname" 
                  label="Имя пользователя" 
                  required
                  />
              </v-flex>
            </v-layout>

            <v-layout wrap>
              <v-flex xs4 class="font-weight-bold">Номер карты</v-flex>
              <v-flex xs8>
                <v-text-field
                  v-model="newUser.cardId" 
                  label="Номер карты" 
                  required
                  />
              </v-flex>
            </v-layout>

            <v-layout wrap>
              <v-flex xs4 class="font-weight-bold">Баланс счета</v-flex>
              <v-flex xs8>
                <v-text-field
                  v-model="newUser.account" 
                  label="Баланс счета" 
                  required
                  />
              </v-flex>
            </v-layout>

            <v-layout wrap>
              <v-flex xs4 class="font-weight-bold">Количество товара</v-flex>
              <v-flex xs8>
                <v-text-field
                  v-model="newUser.amount" 
                  label="Баланс счета" 
                  required
                  />
              </v-flex>
            </v-layout>

            <v-layout wrap>
              <v-spacer/>
              <v-btn color="green darken-1" @click="addUser" class="white--text" :disabled="!valid">Добавить</v-btn>
              <v-btn color="red darken-1" @click.native="addUserDialog = false" class="white--text">Отмена</v-btn>
            </v-layout>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    valid: false,
    loading: false,
    addMoneyDialog: false,
    createOrdersDialog: false,
    deleteAllDialog: false,
    addUserDialog: false,
    editUserDialog: false,
    deleteUserDialog: false,
    from: null,
    to: null,
    dateFrom: null,
    dateTo: null,
    moneyAmount: null,
    itemsAmount: null,
    selectedUser: {},
    newUser: {
      nickname: null,
      cardId: null,
      amount: null,
      account: null
    },
    headers: [
      {
        sortable: false,
        text: ''
      },
      {
        sortable: true,
        text: 'Id',
        value: 'id'
      },
      {
        sortable: true,
        text: 'Nickname',
        value: 'nickname'
      },
      {
        sortable: true,
        text: 'Blockchain Id',
        value: 'blockchainToken'
      },
      {
        sortable: true,
        text: 'Регистрация',
        value: 'registered'
      }
    ],
    items: [ ]
  }),
  mounted () {
    this.getUsers()
  },
  methods: {
    getUsers () {
      this.$http.get('/vendingprofiles')
        .then(response => {
          this.items = response.data
        })
    },
    launchFilePicker () {
      this.$refs.file.click()
    },
    launchFilePickerCards () {
      this.$refs.filecards.click()
    },
    handleFileUpload () {
      let formData = new FormData()
      formData.append('file', this.$refs.file.files[0])
      this.loading = true
      this.$http.post('VendingProfiles/bulk/users',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          this.loading = false
          this.getUsers()
        }).catch(e => {
          this.loading = false
          console.log(e)
          this.error = e.response.data
        })
    },
    handleCardsFileUpload () {
      let formData = new FormData()
      formData.append('file', this.$refs.filecards.files[0])
      this.loading = true
      this.$http.post('VendingProfiles/bulk/cards',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          this.loading = false
          this.getUsers()
        }).catch(e => {
          this.loading = false
          console.log(e)
          this.error = e.response.data
        })
    },
    createCAT () {
      this.$http.post('VendingProfiles/bulk/cells/tokens')
        .then(response => {
          this.loading = false
        }).catch(e => {
          this.loading = false
          console.log(e)
          this.error = e.response.data
        })
    },
    transferMoney () {
      this.$http.post('VendingProfiles/bulk/wallets/transfer',
        {
          amount: this.moneyAmount
        }
      )
        .then(response => {
          this.loading = false
          this.addMoneyDialog = false
        }).catch(e => {
          this.loading = false
          this.addMoneyDialog = false
          console.log(e)
          this.error = e.response.data
        })
    },
    createOrders () {
      this.$http.post('VendingProfiles/bulk/orders',
        {
          amount: this.itemsAmount
        }
      )
        .then(response => {
          this.loading = false
          this.createOrdersDialog = false
        }).catch(e => {
          this.loading = false
          this.createOrdersDialog = false
          console.log(e)
          this.error = e.response.data
        })
    },
    deleteAllPopup () {
      this.deleteAllDialog = true
    },
    deleteAll () {
      this.$http.delete('VendingProfiles/bulk')
        .then(response => {
          this.loading = false
          this.deleteAllDialog = false
        }).catch(e => {
          this.loading = false
          console.log(e)
          this.error = e.response.data
        })
    },
    pickToDeleteUser(item){
      this.selectedUser = item;
      this.deleteUserDialog = true;
    },
    pickToEditUser(item){
      this.selectedUser = item;
      this.editUserDialog = true;
    },
    addUser() {
      this.$http.post('/users', this.newUser).
        then(response => {
          //this.addUserDialog = false;
          //this.getUsers();
          console.log(response)
          
          this.setupUser(response.data.id);
        }).
        catch(e => {
            console.log(e)
        });
    },
    setupUser(userId) {
      this.$http.post('/VendingProfiles/'+userId, this.newUser).
        then(response => {
          this.addUserDialog = false;
          this.getUsers();
        }).
        catch(e => {
            console.log(e)
        });
    },
    editUser() {
      this.$http.put('/VendingProfiles/'+ this.selectedUser.id, this.selectedUser).
        then(response => {
          this.editUserDialog = false;
          //this.getMachines();
        }).
        catch(e => {
            console.log(e)
        });
    },
    deleteUser() {
      this.$http.delete('/VendingProfiles/' + this.selectedUser.id).
        then(response => {
          this.deleteUserDialog = false
          this.selectedUser = {};
          this.getUsers();
        }).
        catch(e => {
            console.log(e)
        });
    }
  }
}
</script>
